export default {
  videoURL: 'https://vimeo.com/429028119/639b8024de',
  wallpaper: '/assets/img/tour/wallpaper.jpg',
  onPlay: (controller) => {
    controller.guide.show();
  },
  onEnd: (controller) => {
    controller.guide.hide();
    controller.screen.showPage('vg-end.html');
  },
  sequences: [
    {
      id: 'welcome',
      title: 'Welcome to the Virtual Interactive Tour',
      start: 0,
      end: 1581 / 30,
      onEnter: (controller) => {
        controller.screen.clear();
      },
      onExit: () => {
      },
      sequences: [],
    },
    {
      id: 'piano-genie',
      title: '1 - Piano Genie',
      start: 1620 / 30,
      end: 8013 / 30,
      onEnter: (controller) => {
        controller.screen.showPage('/apps/piano-genie-vg/index.html');
      },
      onExit: () => {},
      sequences: [
        {
          id: 'piano-genie-play',
          start: 2245 / 30,
          end: 2268 / 30,
          onEnter: (controller) => {
            controller.guide.showPrompt("Click here when you're ready to continue.");
          },
          onExit: (controller) => {
            controller.guide.hidePrompt();
          },
        },
      ],
    },
    {
      id: 'neural-numbers',
      title: '2 - Neural Numbers',
      start: 8043 / 30,
      end: 14420 / 30,
      onEnter: (controller) => {
        controller.guide.showLoader();
        controller.screen.showPage('/vg-neural-numbers.html').then(() => {
          controller.guide.hideLoader();
        });
        controller.screen.preloadImage('/assets/img/tour/neural-numbers-network.jpg');
        controller.screen.preloadImage('/assets/img/tour/neural-numbers-mnist.jpg');
      },
      onExit: () => {},
      sequences: [
        {
          id: 'neural-numbers-try',
          start: 8674 / 30,
          end: 8703 / 30,
          onEnter: (controller) => {
            controller.guide.showPrompt("Click here when you're ready to continue.");
          },
          onExit: (controller) => {
            controller.guide.hidePrompt();
          },
        },
        {
          id: 'neural-numbers-with-normalizer',
          start: 10189 / 30,
          end: 14420 / 30,
          onEnter: (controller) => {
            controller.screen.sendMessageToPage({ type: 'showNormalizer' });
          },
          onExit: (controller) => {
            controller.screen.sendMessageToPage({ type: 'hideNormalizer' });
          },
          sequences: [
            {
              id: 'neural-numbers-normalize',
              start: 11098 / 30,
              end: 11123 / 30,
              onEnter: (controller) => {
                controller.guide.showPrompt("Click here when you're ready to continue.");
              },
              onExit: (controller) => {
                controller.guide.hidePrompt();
              },
            },
            {
              id: 'neural-numbers-with-output',
              start: 11175 / 30,
              end: 14420 / 30,
              onEnter: (controller) => {
                controller.screen.sendMessageToPage({ type: 'showOutput' });
              },
              onExit: (controller) => {
                controller.screen.sendMessageToPage({ type: 'hideOutput' });
              },
              sequences: [
                {
                  id: 'neural-numbers-output',
                  start: 11944 / 30,
                  end: 11970 / 30,
                  onEnter: (controller) => {
                    controller.guide.showPrompt("Click here when you're ready to continue.");
                  },
                  onExit: (controller) => {
                    controller.guide.hidePrompt();
                  },
                },
                {
                  id: 'neural-numbers-network',
                  start: 12055 / 30,
                  end: 12742 / 30,
                  onEnter: (controller) => {
                    controller.screen.showImage('/assets/img/tour/neural-numbers-network.jpg');
                  },
                  onExit: (controller) => {
                    controller.screen.hideImage();
                  },
                },
                {
                  id: 'neural-numbers-mnist',
                  start: 12742 / 30,
                  end: 13991 / 30,
                  onEnter: (controller) => {
                    controller.screen.showImage('/assets/img/tour/neural-numbers-mnist.jpg');
                  },
                  onExit: (controller) => {
                    controller.screen.hideImage();
                  },
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: 'gradient-descent',
      title: '3 - Gradient Descent',
      start: 14440 / 30,
      end: 23322 / 30,
      onEnter: (controller) => {
        controller.guide.showLoader();
        controller.screen.showPage('/apps/gradient-descent-vg/index.html').then(() => {
          controller.guide.hideLoader();
        });
        controller.screen.preloadImage('/assets/img/tour/gradient-descent-global.png');
        controller.screen.preloadImage('/assets/img/tour/gradient-descent-local.png');
        controller.screen.preloadImage('/assets/img/tour/gradient-descent-rocky.png');
      },
      onExit: () => {},
      sequences: [
        {
          id: 'gradient-descent-play',
          start: 15866 / 30,
          end: 15897 / 30,
          onEnter: (controller) => {
            controller.guide.showPrompt("Click here when you're ready to continue.");
          },
          onExit: (controller) => {
            controller.guide.hidePrompt();
          },
        },
        {
          id: 'gradient-descent-bottom',
          start: 18288 / 30,
          end: 21104 / 30,
          onEnter: () => {},
          onExit: () => {},
          sequences: [
            {
              id: 'gradient-descent-global',
              start: 18288 / 30,
              end: 18617 / 30,
              onEnter: (controller) => {
                controller.screen.showImage('/assets/img/tour/gradient-descent-global.png');
              },
              onExit: (controller) => {
                controller.screen.hideImage();
              },
            },
            {
              id: 'gradient-descent-local',
              start: 18617 / 30,
              end: 20568 / 30,
              onEnter: (controller) => {
                controller.screen.showImage('/assets/img/tour/gradient-descent-local.png');
              },
              onExit: (controller) => {
                controller.screen.hideImage();
              },
            },
            {
              id: 'gradient-descent-rocky',
              start: 20568 / 30,
              end: 21104 / 30,
              onEnter: (controller) => {
                controller.screen.showImage('/assets/img/tour/gradient-descent-rocky.png');
              },
              onExit: (controller) => {
                controller.screen.hideImage();
              },
            },
          ],
        },
      ],
    },
    {
      id: 'closing',
      title: 'Conclusion',
      start: 23332 / 30,
      end: 25693 / 30,
      onEnter: (controller) => {
        controller.screen.clear();
      },
      onExit: () => {},
      sequences: [],
    },
  ],
};
