$('.vg-neural-numbers').each((i, element) => {
  const $container = $(element);
  $(window).on('message', (ev) => {
    const { data } = ev.originalEvent;
    switch (data.type) {
      case 'showNormalizer':
        $container.find('.stage-normalize').addClass('visible');
        break;
      case 'hideNormalizer':
        $container.find('.stage-normalize').removeClass('visible');
        break;
      case 'showOutput':
        $container.find('.stage-bars').addClass('visible');
        break;
      case 'hideOutput':
        $container.find('.stage-bars').removeClass('visible');
        break;
      default:
        break;
    }
  });
});
