/* globals IMAGINARY */
import TourController from './tour-controller';
import TourGuide from './tour-guide';
import EnglishTour from './virtual-guided-tour-en';
import GermanTour from './virtual-guided-tour-de';
import TourScreen from './tour-screen';

const tours = {
  en: EnglishTour,
  de: GermanTour,
};

$('.tour-main').each(async (i, element) => {
  const $container = $(element);
  const lang = $container.attr('data-lang') || 'en';
  if (!tours[lang]) {
    console.error(`Requested tour for ${lang} does not exist.`);
  }
  const tour = tours[lang];

  await IMAGINARY.i18n.init({
    queryStringVariable: 'lang',
    translationsDirectory: '/tr/virtual-tour',
    defaultLanguage: 'en',
  });
  await IMAGINARY.i18n.setLang(lang);

  async function enterFullScreen() {
    if (!document.fullscreenElement) {
      $('body')[0].requestFullscreen().catch((err) => {
        alert(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`);
      });
      return Promise.resolve();
    }
    return document.exitFullscreen();
  }

  const $navBar = $('<div class="tour-nav"></div>').appendTo($container);
  // Exit button
  $('<button class="tour-nav-exit"></button>')
    .append($('<i class="arrow fa fa-arrow-left"></i>'))
    .append($('<div class="logo"></div>'))
    .appendTo($navBar)
    .on('click', () => {
      window.location.href = 'index.html';
    });

  const chapterDropdown = $('<div class="dropdown-menu"></div>');

  const chapterSelectButton = $('<button type="button" class="dropdown-toggle" data-toggle="dropdown"></button>')
    .html('&nbsp;');

  // Episode dropdown
  $('<div class="dropdown tour-chapter-sel"></div>')
    .append(chapterSelectButton)
    .append(chapterDropdown)
    .appendTo($navBar);

  const pauseButton = $('<button type="button"></button>')
    .append($('<i class="fa fa-play"></i>')
      .attr('title', IMAGINARY.i18n.t('PLAY')));

  const backwardsButton = $('<button type="button"></button>')
    .addClass('button-backward')
    .append($('<i class="fa fa-backward"></i>')
      .attr('title', IMAGINARY.i18n.t('BACK_30')));

  const forwardsButton = $('<button type="button"></button>')
    .addClass('button-forward')
    .append($('<i class="fa fa-forward"></i>')
      .attr('title', IMAGINARY.i18n.t('FORWARD_30')));

  const fullScreenButton = $('<button type="button"></button>')
    .append($('<i class="fa fa-expand" title="Full screen mode"></i>')
      .attr('title', IMAGINARY.i18n.t('FULL_SCREEN')))
    .on('click', enterFullScreen);

  if ($('body')[0].requestFullscreen === undefined) {
    fullScreenButton.attr('disabled', true).addClass('disabled');
  }

  $('<div class="tour-controls"></div>')
    .append(pauseButton)
    .append(backwardsButton)
    .append(forwardsButton)
    .append(fullScreenButton)
    .appendTo($navBar);
  // Pause
  // back N secs
  // forward N secs
  // full screen

  const $screenContainer = $('<div></div>')
    .appendTo($container);

  $('<div class="tour-footer"></div>').appendTo($container);

  function doLayout() {

  }

  // throttled resize
  const resizeDelay = 250;
  let resizeTimeout = null;
  $(window).on('resize', () => {
    if (!resizeTimeout) {
      resizeTimeout = setTimeout(() => {
        resizeTimeout = null;
        doLayout();
      }, resizeDelay);
    }
  });

  const guide = new TourGuide($container, tour);
  const screen = new TourScreen($screenContainer, tour);
  const controller = new TourController(tour, guide, screen);

  window.Tour = { guide, screen, controller };

  Object.entries(controller.getChapters()).forEach(([id, title]) => {
    chapterDropdown.append($('<a href="#" class="dropdown-item"></a>')
      .text(title)
      .on('click', (ev) => {
        ev.preventDefault();
        controller.goToChapter(id);
        guide.play();
      }));
  });

  controller.events.on('sequenceEntered', (seq) => {
    if (seq.title) {
      chapterSelectButton.html(seq.title);
    }
  });

  controller.events.on('sequenceExited', (seq) => {
    if (seq.title) {
      chapterSelectButton.html('&nbsp;');
    }
  });

  async function refreshPauseButton() {
    if (await guide.isPaused()) {
      pauseButton.html(`<i class="fa fa-play" title="${IMAGINARY.i18n.t('PLAY')}"></i>`);
    } else {
      pauseButton.html(`<i class="fa fa-pause" title="${IMAGINARY.i18n.t('PAUSE')}"></i>`);
    }
  }

  pauseButton.on('click', async (ev) => {
    ev.preventDefault();
    if (await guide.isPaused()) {
      await guide.play();
    } else {
      await guide.pause();
    }
    await refreshPauseButton();
  });

  forwardsButton.on('click', (ev) => {
    ev.preventDefault();
    guide.forward(30);
  });

  backwardsButton.on('click', (ev) => {
    ev.preventDefault();
    guide.back(30);
  });

  guide.events.on('paused', async () => {
    await refreshPauseButton();
  });

  guide.events.on('playing', async () => {
    await refreshPauseButton();
  });

  guide.events.on('play', async () => {
    await refreshPauseButton();
  });
});
