/* globals SVG */
$('[data-component=eggy]').each((i, container) => {
  const draw = SVG().addTo(container).size(500, 500);
  draw.viewbox(0, 0, 500, 500);

  const gradient = draw.gradient('radial', (add) => {
    add.stop({ offset: 0, color: '#FDEB02', opacity: 0.8 });
    add.stop({ offset: 0.4, color: '#FDEB02', opacity: 0.4 });
    add.stop({ offset: 1, color: '#FDEB02', opacity: 0 });
  }).radius(0.5);

  const box = draw.viewbox();
  const maxRadius = Math.max(box.width, box.height) * 0.3;
  const minRadius = maxRadius * 0.75;

  const circleA = draw.circle(maxRadius * 2).center(250, 250).fill(gradient);
  const circleB = draw.circle(minRadius * 2).center(275, 260).fill(gradient);
  const minX = box.x + maxRadius;
  const maxX = box.width - maxRadius;
  const minY = box.y + maxRadius;
  const maxY = box.height - maxRadius;
  const maxXDrift = maxRadius;
  const maxYDrift = maxRadius * 0.5;
  let xDrift = 0;
  let yDrift = 0;
  let targetXDrift = 0;
  let targetYDrift = 0;

  // circleA.fill('red');
  // circleB.fill('blue');

  const meander = () => {
    const target = {
      x: minX + Math.random() * (maxX - minX),
      y: minY + Math.random() * (maxY - minY),
    };

    circleA.animate({
      duration: 2500,
      when: 'now',
      swing: true,
    }).ease('<')
      .cx(target.x)
      .ease('>')
      .cy(target.y)
      .after(meander);
  };
  meander();

  const follow = () => {
    xDrift = targetXDrift;
    yDrift = targetYDrift;
    targetXDrift = Math.random() * maxXDrift * 2 - maxXDrift;
    targetYDrift = Math.random() * maxYDrift * 2 - maxYDrift;

    circleB.animate({
      duration: 3500,
      when: 'now',
      swing: true,
    }).ease('<>')
      .during((delta) => {
        circleB.cx(circleA.cx() + xDrift * (1 - delta) + targetXDrift * delta);
        circleB.cy(circleA.cy() + yDrift * (1 - delta) + targetYDrift * delta);
      })
      .after(follow);
  };
  follow();

  const breathe = () => {
    const newRadius = minRadius + Math.random() * (maxRadius - minRadius);
    circleB.animate({
      duration: 1500,
      when: 'now',
    }).attr({ r: newRadius })
      .after(breathe);
  };
  breathe();
});
