// eslint-disable-next-line import/no-extraneous-dependencies
import Countdown from 'countdown';

// Countdown
$('[data-component=countdown]').each((i, countdownElement) => {
  const goal = new Date(Date.UTC(2020, 5, 10, 2, 0, 0));
  const fields = ['months', 'days', 'hours', 'minutes', 'seconds'];
  const containers = {};
  fields.forEach((field) => {
    containers[field] = $(countdownElement).find(`.countdown-item-${field} .value`);
  });
  let zeroed = false;
  Countdown(
    (timeSpan) => {
      const now = new Date();
      if (now < goal) {
        fields.forEach((field) => {
          containers[field].text(timeSpan[field]);
        });
      } else if (!zeroed) {
        fields.forEach((field) => {
          containers[field].text(0);
        });
        zeroed = true;
      }
    },
    // Start of the event: 10 Jun 2020, 0:00 (local time)
    goal,
    // eslint-disable-next-line no-bitwise
    Countdown.MONTHS | Countdown.DAYS | Countdown.HOURS | Countdown.MINUTES | Countdown.SECONDS
  );
});
