export default class TourScreen {
  constructor(container, tour) {
    this.$element = $('<div class="tour-screen"></div>')
      .appendTo(container);
    this.tour = tour;
    this.preloadedImages = [];

    this.$pageIframe = $('<iframe>')
      .attr('src', 'about:blank')
      .appendTo($('<div class="iframe-wrapper"></div>')
        .appendTo(this.$element));

    this.$imageContainer = $('<div class="image-wrapper"></div>')
      .appendTo(this.$element);

    this.clear();
  }

  clear() {
    this.$imageContainer.empty();
    if (this.tour.wallpaper) {
      this.showImage(this.tour.wallpaper);
    }
  }

  showImage(url) {
    this.$imageContainer.empty().append(
      $('<img></img>')
        .one('load', () => {
          this.$imageContainer.addClass('visible');
        })
        .attr('src', url)
    );
  }

  hideImage() {
    this.$imageContainer.removeClass('visible');
  }

  preloadImage(url) {
    const img = new Image();
    img.src = url;
    this.preloadedImages.push(img);
  }

  showPage(url) {
    return new Promise((resolve) => {
      this.$pageIframe
        .one('load', () => {
          resolve(this.$pageIframe.contents());
          this.$pageIframe.focus();
        })
        .attr('src', url);
      this.hideImage();
    });
  }

  sendMessageToPage(msg) {
    this.$pageIframe[0].contentWindow.postMessage(msg);
  }
}
