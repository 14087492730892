import EventEmitter from 'events';
import { isPromise } from './helpers';

export default class TourController {
  constructor(tour, guide, screen) {
    if (!TourController.validateTour(tour)) {
      throw new Error('Invalid tour definition');
    }
    this.tour = tour;
    this.guide = guide;
    this.screen = screen;

    this.events = new EventEmitter();
    this.activeSequences = [];

    this.guide.events.on('cuepoint', ev => this.handleAtTime(ev.time));
    this.guide.events.on('skip', ev => this.handleAtTime(ev.time));
    this.guide.events.on('ended', () => {
      if (this.tour.onEnd) {
        this.tour.onEnd(this);
      }
    });
    this.guide.events.on('play', () => {
      if (this.tour.onPlay) {
        this.tour.onPlay(this);
      }
    });
  }

  static validateTour(tour) {
    const validateSequences = (seqList, parentStart = null, parentEnd = null) => {
      return seqList.every((seq) => {
        const mandatoryProperties = ['id', 'start', 'end'];
        if (!mandatoryProperties.every(prop => seq[prop] !== undefined)) {
          console.error(`Sequence ${seq.id} missing mandatory property`);
          return false;
        }
        if (seq.end < seq.start) {
          console.error(`Start time > End time in sequence ${seq.id}`);
          return false;
        }
        if (parentStart && seq.start < parentStart) {
          console.error(`Start time < parent start time in sequence ${seq.id}`);
          return false;
        }
        if (parentEnd && seq.end > parentEnd) {
          console.error(`End time > parent end time in sequence ${seq.id}`);
          return false;
        }
        if (seq.sequences) {
          return validateSequences(seq.sequences, seq.start, seq.end);
        }
        return true;
      });
    };

    if (!tour.sequences) {
      console.error('Tour has no sequences property');
      return false;
    }
    if (!tour.videoURL) {
      console.error('Tour has no videoURL property');
      return false;
    }
    if (!tour.sequences.every(seq => seq.title !== undefined)) {
      console.error('Top level sequences should have a title property');
      return false;
    }
    return validateSequences(tour.sequences);
  }

  getChapters() {
    return Object.fromEntries(this.tour.sequences.map(seq => [seq.id, seq.title]));
  }

  goToChapter(id) {
    const chapterSeq = this.tour.sequences.find(seq => seq.id === id);
    if (chapterSeq) {
      this.guide.goTo(chapterSeq.start);
    }
  }

  isInSequence(id) {
    return !!this.activeSequences.find(seq => seq.id === id);
  }

  enterSequence(seq) {
    this.activeSequences.push(seq);
    if (seq.onEnter) {
      const rc = seq.onEnter(this);
      if (isPromise(rc)) {
        // Enter "Loading" state
        rc.then(() => {
          // Exit "Loading" state
        });
      }
    }
    this.events.emit('sequenceEntered', Object.assign({}, seq));
  }

  exitSequence(seq) {
    this.activeSequences = this.activeSequences.filter(actSeq => actSeq.id !== seq.id);
    if (seq.onExit) {
      const rc = seq.onExit(this);
      if (isPromise(rc)) {
        // Enter "Loading" state
        rc.then(() => {
          // Exit "Loading" state
        });
      }
    }
    this.events.emit('sequenceExited', Object.assign({}, seq));
  }

  handleAtTime(seconds) {
    // Check if we reached the end of any active sequences
    // and exit them in order (inside-out)
    const seqsToExit = [];
    this.activeSequences.forEach((seq) => {
      if ((seconds < seq.start || seconds >= seq.end)) {
        seqsToExit.push(seq);
      }
    });
    // This extra step is to avoid doing a copy of the activeSequences array
    // on each invocation, to support polling in the future if cuePoints
    // have issues.
    if (seqsToExit.length > 0) {
      seqsToExit.slice().reverse().forEach(seq => this.exitSequence(seq));
    }

    // Check if we're within any sequences
    // and enter them in order (outside-in)
    const checkIfWithinSeqs = (seqList) => {
      seqList.forEach((seq) => {
        if (seconds >= seq.start
          && seconds < seq.end
          && !this.isInSequence(seq.id)) {
          this.enterSequence(seq);
        }
        if (seq.sequences) {
          checkIfWithinSeqs(seq.sequences);
        }
      });
    };
    checkIfWithinSeqs(this.tour.sequences);
  }
}
