/* globals Vimeo */
import EventEmitter from 'events';

export default class TourGuide {
  constructor(container, tour) {
    this.events = new EventEmitter();
    this.tour = tour;
    this.hasPrompt = false;
    this.hasLoader = false;
    this.wasPaused = false;

    this.$element = $('<div class="tour-guide"></div>')
      .appendTo(container);

    const $playerWrapper = $('<div class="tour-guide-video"></div>')
      .appendTo(this.$element);

    this.$button = $('<div class="tour-guide-button"></div>')
      .appendTo(this.$element)
      .on('click', (ev) => {
        ev.preventDefault();
        this.events.emit('promptClicked');
        this.hidePrompt();
      })
      .hide();

    this.$buttonText = $('<div class="tour-guide-button-text"></div>')
      .appendTo(this.$button);

    this.$loader = $('<div class="tour-guide-loader"></div>')
      .append($('<div class="lds-ellipsis"></div>')
        .append($('<div></div>')).append($('<div></div>')).append($('<div></div>')))
      .appendTo(this.$element)
      .hide();

    this.$playButton = $('<div class="tour-guide-play-button"></div>')
      .append($('<div class="tour-guide-play-button-text"></div>')
        .html('<i class="fa fa-play"></i> Start'))
      .appendTo(this.$element)
      .on('click', (ev) => {
        ev.preventDefault();
        this.play();
      });

    this.vplayer = new Vimeo.Player($playerWrapper[0], {
      url: tour.videoURL,
      autoplay: true,
      controls: false,
      title: false,
      width: 400,
      muted: false,
    });

    this.vplayer.on('cuepoint', ({ data }) => {
      console.log(`Entered ${data.id}-${data.type}`);
      this.events.emit('cuepoint', {
        sequenceID: data.id,
        type: data.type,
        time: data.time,
      });
    });

    this.vplayer.on('ended', () => {
      this.events.emit('ended');
    });

    this.vplayer.on('play', () => {
      this.hidePlayButton();
      this.events.emit('play');
    });

    const createCuePoints = (seqList) => {
      seqList.forEach((seq) => {
        this.vplayer.addCuePoint(seq.start, { id: seq.id, type: 'start', time: seq.start });
        if (seq.sequences) {
          createCuePoints(seq.sequences);
        }
        this.vplayer.addCuePoint(seq.end, { id: seq.id, type: 'end', time: seq.end });
      });
    };
    createCuePoints(tour.sequences);
    this.show();
    this.showPlayButton();
  }

  hide() {
    this.$element.removeClass('visible');
  }

  show() {
    this.$element.addClass('visible');
  }

  async pause() {
    this.wasPaused = true;
    await this.vplayer.pause().then(() => {
      this.events.emit('paused');
    });
  }

  async play() {
    this.wasPaused = false;
    await this.vplayer.play().then(() => {
      this.events.emit('playing');
    });
  }

  async isPaused() {
    return this.vplayer.getPaused();
  }

  async back(deltaSeconds) {
    const currTime = await this.vplayer.getCurrentTime();
    const newTime = Math.max(currTime - deltaSeconds, 0);
    await this.vplayer.setCurrentTime(newTime);
    this.events.emit('skip', { time: newTime });
  }

  async forward(deltaSeconds) {
    const currTime = await this.vplayer.getCurrentTime();
    const duration = await this.vplayer.getDuration();
    const newTime = currTime + deltaSeconds;
    if (newTime < duration) {
      await this.vplayer.setCurrentTime(newTime);
      this.events.emit('skip', { time: newTime });
    }
  }

  async goTo(seconds) {
    await this.vplayer.setCurrentTime(seconds);
    this.events.emit('skip', { time: seconds });
  }

  async showLoader() {
    this.$loader.show();
    this.hasLoader = true;
    await this.pause();
  }

  async hideLoader() {
    this.$loader.hide();
    this.hasLoader = false;
    await this.resumePlaying();
  }

  async showPrompt(text) {
    this.$element.addClass('button-mode');
    this.$buttonText.html(text);
    this.$button.show();
    this.hasPrompt = true;
    await this.pause();
  }

  async hidePrompt() {
    this.$element.removeClass('button-mode');
    this.$button.hide();
    this.hasPrompt = false;
    await this.resumePlaying();
  }

  async resumePlaying() {
    if (!this.hasPrompt && !this.hasLoader) {
      await this.play();
    }
  }

  hidePlayButton() {
    this.$element.removeClass('button-mode');
    this.$playButton.hide();
  }

  showPlayButton() {
    this.$element.addClass('button-mode');
    this.$playButton.show();
  }
}
